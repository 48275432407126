// Если нам надо оплучить глобальные данные
// import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';

export default {
	get(parametrs, tableName) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`https://apiosnok.minzdrav.gov.ru/LookUps/GetLookUps?tableName=${tableName}`, parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
