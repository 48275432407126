<template>
	<div class="flex flex-col w-screen sm:w-auto px-4 py-6 sm:px-4 sm:py-6 gap-5">
		<!-- Таблица -->
		<TableGuide />
	</div>
</template>

<script>
// Глобальные данные стора
// import { getters } from '@/store/store';

import TableGuide from './TableGuide.vue';

export default {
	name: 'Main',
	components: { TableGuide }
};
</script>
